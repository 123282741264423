import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CommonButton } from './pages/CommonStyles';

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  &:hover {

    background-color: #007BFF;
    text-decoration: underline;
  }
`;

const NavLinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 60px;
  height: calc(100% - 60px);
  width: 250px;
  background-color: #333;
  transform: ${props => (props.$isopen ? "translateX(0)" : "translateX(-250px)")};
  transition: transform 0.3s ease;
  z-index: 3;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;
`;

const SignOutButton = styled(CommonButton)`
  margin-bottom: 40px;
`;

const NavLinks = ({ isopen, setisopen, onSignOut }) => {
  return (
    <NavLinksContainer $isopen={isopen}>
      <LinksWrapper>
        <StyledLink to="/bills-list" onClick={() => setisopen(false)}>Bills List</StyledLink>
        <StyledLink to="/budget-summary" onClick={() => setisopen(false)}>Budget Summary</StyledLink>
        <StyledLink to="/daily-hours" onClick={() => setisopen(false)}>Daily Hours</StyledLink>
        <StyledLink to="/loans-debt" onClick={() => setisopen(false)}>Loans & Debts</StyledLink>
        <StyledLink to="/meal-plan" onClick={() => setisopen(false)}>Meal Plan</StyledLink>
        <StyledLink to="/money-summary" onClick={() => setisopen(false)}>Money Summary</StyledLink>
        <StyledLink to="/money-over-time" onClick={() => setisopen(false)}>Money Over Time</StyledLink>
        <StyledLink to="/notes" onClick={() => setisopen(false)}>Notes</StyledLink>
        <StyledLink to="/shopping-list" onClick={() => setisopen(false)}>Shopping List</StyledLink>
        <StyledLink to="/timeline" onClick={() => setisopen(false)}>Timeline</StyledLink>
        <StyledLink to="/transactions" onClick={() => setisopen(false)}>Transactions</StyledLink>
      </LinksWrapper>
      <SignOutButton onClick={onSignOut} style={{ marginTop: 'auto' }}>Sign Out</SignOutButton>
    </NavLinksContainer>
  );
};

NavLinks.propTypes = {
  onSignOut: PropTypes.func,
  setisopen: PropTypes.func,
  isopen: PropTypes.bool.isRequired,
};


export default NavLinks;
