import React, { useEffect, useState } from 'react';
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
} from 'firebase/firestore';
import { firestore } from '../firebase';
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import {
  CommonContainer,
  CommonTitle,
  CommonBottomBar
} from './CommonStyles';

const MoneyOverTime = () => {
  const [monthlyData, setMonthlyData] = useState([]);
  const [budgetData, setBudgetData] = useState([]);
  const [budgetPercentageData, setBudgetPercentageData] = useState([]);
  const [totalSpending, setTotalSpending] = useState(0);
  const [categoryColors, setCategoryColors] = useState({});

  useEffect(() => {
    const fetchBudgets = async () => {
      try {
        const budgetQuery = query(collection(firestore, 'categories'));
        const budgetSnapshot = await getDocs(budgetQuery);
        const budgets = {};
        const colors = {};
        budgetSnapshot.forEach((doc) => {
          const budget = doc.data();
          if (budget.name !== 'HOA') {
            budgets[budget.name] = parseFloat(budget.amount);
            if (!budget.color) {
              throw new Error(`No color found for category: ${budget.name}`);
            }
            colors[budget.name] = budget.color;
          }
        });
        setCategoryColors(colors);
        return budgets;
      } catch (error) {
        console.error('Error fetching budgets:', error);
        return {};
      }
    };

    const fetchMonthlyData = async () => {
      try {
        const today = new Date();
        const startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1);
        const endDate = new Date(today.getFullYear(), today.getMonth(), 1);

        const budgets = await fetchBudgets();

        const q = query(
          collection(firestore, 'transactions'),
          where('date', '>=', startDate),
          where('date', '<', endDate),
          orderBy('date', 'asc')
        );

        const querySnapshot = await getDocs(q);

        const groupedData = {};
        const categories = new Set();

        querySnapshot.forEach((doc) => {
          const transaction = doc.data();
          const amount = parseFloat(transaction.amount);
          const category = transaction.category || 'Uncategorized';

          if (['HOA', 'Subscriptions', 'Medical Expenses', 'Internet & Phone'].includes(category)) return;

          const monthYear = `${new Intl.DateTimeFormat('en-US', { month: 'short' }).format(transaction.date.toDate())} ${transaction.date.toDate().getFullYear()}`;

          if (!groupedData[monthYear]) {
            groupedData[monthYear] = {};
          }

          if (!groupedData[monthYear][category]) {
            groupedData[monthYear][category] = 0;
          }

          groupedData[monthYear][category] += amount;
          categories.add(category);
        });

        const formattedData = Object.entries(groupedData).map(([monthYear, categoryData]) => {
          const dataPoint = { monthYear };
          categories.forEach(category => {
            dataPoint[category] = categoryData[category] || 0;
          });
          return dataPoint;
        });

        const budgetComparisonData = Object.entries(groupedData).map(([monthYear, categoryData]) => {
          const dataPoint = { monthYear };
          categories.forEach(category => {
            const spent = categoryData[category] || 0;
            const budget = budgets[category] || 0;
            const difference = budget - spent;
            if (budget > 0 && Math.abs(difference / budget) > 0.1) {
              dataPoint[`${category} vs Budget`] = difference;
            }
          });
          return dataPoint;
        });

        const budgetPercentageComparisonData = Object.entries(groupedData).map(([monthYear, categoryData]) => {
          const dataPoint = { monthYear };
          categories.forEach(category => {
            const spent = categoryData[category] || 0;
            const budget = budgets[category] || 0;
            if (budget > 0) {
              const percentage = ((spent - budget) / budget) * 100;
              if (Math.abs(percentage) > 10) {
                dataPoint[`${category} % vs Budget`] = percentage;
              }
            }
          });
          return dataPoint;
        });

        const total = Object.values(formattedData).reduce((acc, month) => {
          return acc + Object.values(month).reduce((sum, val) => {
            return typeof val === 'number' ? sum + val : sum;
          }, 0);
        }, 0);

        setTotalSpending(total);
        setMonthlyData(formattedData);
        setBudgetData(budgetComparisonData);
        setBudgetPercentageData(budgetPercentageComparisonData);
      } catch (error) {
        console.error('Error fetching monthly data:', error);
      }
    };

    fetchMonthlyData();
  }, []);

  const getCategoryColor = (category) => {
    if (!categoryColors[category]) {
      throw new Error(`No color found for category: ${category}`);
    }
    return categoryColors[category];
  };

  const categories = monthlyData.length > 0
    ? Object.keys(monthlyData[0]).filter(key => key !== 'monthYear')
    : [];

  const hasSignificantDeviation = (category) => {
    if (monthlyData.length < 2) return false;

    const values = monthlyData.map(data => data[category]);
    const maxVal = Math.max(...values);
    const minVal = Math.min(...values);

    return (maxVal - minVal) / minVal > 0.50;
  };

  const categoriesWithDeviation = categories.filter(hasSignificantDeviation);

  const significantBudgetCategories = budgetData.length > 0
    ? Object.keys(budgetData[0])
      .filter(key => key !== 'monthYear' && key.endsWith('vs Budget'))
      .map(key => key.replace(' vs Budget', ''))
    : [];

  const significantBudgetPercentageCategories = budgetPercentageData.length > 0
    ? Object.keys(budgetPercentageData[0])
      .filter(key => key !== 'monthYear' && key.endsWith('% vs Budget'))
      .map(key => key.replace(' % vs Budget', ''))
    : [];

  return (
    <CommonContainer>
      <CommonTitle>
        Spending Timeline - Previous 6 Months
      </CommonTitle>
      {monthlyData.length > 0 && (
        <>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={monthlyData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="monthYear" />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              {categories.map((category, index) => (
                <Line
                  key={category}
                  type="monotone"
                  dataKey={category}
                  stroke={getCategoryColor(category)}
                  dot={true}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>

          <CommonTitle>
            Spending Timeline (Significant Changes Only)
          </CommonTitle>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={monthlyData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="monthYear" />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              {categoriesWithDeviation.map((category, index) => (
                <Line
                  key={category}
                  type="monotone"
                  dataKey={category}
                  stroke={getCategoryColor(category)}
                  dot={true}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>

          <CommonTitle>
            Budget Comparison by Category (10% Deviation)
          </CommonTitle>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={budgetData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="monthYear" />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              {significantBudgetCategories.map((category, index) => (
                <Bar
                  key={category}
                  dataKey={`${category} vs Budget`}
                  fill={getCategoryColor(category)}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>

          <CommonTitle>
            Budget Comparison by Category (Percentage)
          </CommonTitle>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={budgetPercentageData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="monthYear" />
              <YAxis unit="%" />
              <Tooltip formatter={(value) => `${value.toFixed(1)}%`} />
              <Legend />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              {significantBudgetPercentageCategories.map((category, index) => (
                <Bar
                  key={category}
                  dataKey={`${category} % vs Budget`}
                  fill={getCategoryColor(category)}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
      <CommonBottomBar>
        <div>Total Spent: ${totalSpending.toFixed(2)}</div>
      </CommonBottomBar>
    </CommonContainer>
  );
};

export default MoneyOverTime;
