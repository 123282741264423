import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import {
  collection,
  getDocs,
  addDoc,
  query,
  where,
  orderBy,
  updateDoc,
  doc
} from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import styled from 'styled-components';
import {
  CommonContainer,
  CommonInput,
  CommonTitle,
  CommonButton,
  CommonTableCell,
  CommonBottomBar,
} from './CommonStyles';

const TableContainer = styled.div`
  caret-color: transparent;
  margin-top: 20px;
  overflow-x: auto;
  z-index: 0;
  display: block;
  max-width: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-top: 10px;
  width: auto;
  min-width: 100%;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  cursor: pointer;
  font-size: .7em;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled(CommonTableCell)`
  max-width: 110px;
`;

const StyledSelect = styled.select`
  padding: 5px;
  font-size: .7em;
  margin: 5px;
  max-width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 768px) {
    max-width: none;
  }
`;

const Transactions = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [totalAmount, setTotalAmount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editAmount, setEditAmount] = useState('');
  const [showFullYear, setShowFullYear] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [invertSelection, setInvertSelection] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const categoryGroups = {
    'Bills': ['taxes', 'internet & phone', 'mortgage', 'subscriptions', 'hoa', 'tithe', 'car payment', 'student loans', 'medical expenses', 'roof payment', 'insurance', 'utilities'],
    'Food': ['groceries', 'dinning', 'beverages'],
    'Extra': ['personal', 'out of band', 'car accessories'],
    'Unknown': [null, undefined, '']
  };

  const refreshDataCall = async () => {
    try {
      const user = auth.currentUser;
      const token = user && (await user.getIdToken());

      await axios.get('https://us-west1-chores-7bdc9.cloudfunctions.net/auth', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setTimeout(async () => {
        setRefreshData(prev => !prev);
      }, 5000);

    } catch (error) {
      console.error('Error fetching new data:', error.message);
    }
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handlePrevMonth = () => {
    if (!showFullYear) {
      const newMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      const newYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      setCurrentMonth(newMonth);
      setCurrentYear(newYear);
      setRefreshData(prev => !prev);
    }
  };

  const handleNextMonth = () => {
    if (!showFullYear) {
      const newMonth = currentMonth === 11 ? 0 : currentMonth + 1;
      const newYear = currentMonth === 11 ? currentYear + 1 : currentYear;
      setCurrentMonth(newMonth);
      setCurrentYear(newYear);
      setRefreshData(prev => !prev);
    }
  };

  const handleShowFullYear = () => {
    setShowFullYear((prevShowFullYear) => !prevShowFullYear);
    setCurrentMonth(new Date().getMonth());
  };

  const handleKeyDown = async (itemId, e) => {
    if (e.key === 'Enter') {
      setIsEditing(null);
      await handleAmountEdit(itemId, editAmount);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(firestore, 'categories'));
        const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data().name);
        const sortedCategoriesData = categoriesData.sort((a, b) => a.localeCompare(b));
        setCategories(sortedCategoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error.message);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesSnapshot = await getDocs(collection(firestore, 'categories'));
        const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data().name);
        const sortedCategoriesData = categoriesData.sort((a, b) => a.localeCompare(b));
        setCategories(sortedCategoriesData);

        let q;
        if (showFullYear) {
          const startOfYear = new Date(currentYear, 0, 1);
          const endOfYear = new Date(currentYear, 11, 31);
          q = query(
            collection(firestore, 'transactions'),
            where('date', '>=', startOfYear, '&&', 'date', '<=', endOfYear),
            orderBy(sortColumn, sortDirection)
          );
        } else {
          const startOfMonth = new Date(currentYear, currentMonth, 1);
          const endOfMonth = new Date(currentYear, currentMonth + 1, 0);
          q = query(
            collection(firestore, 'transactions'),
            where('date', '>=', startOfMonth, '&&', 'date', '<=', endOfMonth),
            orderBy(sortColumn, sortDirection)
          );
        }

        const querySnapshot = await getDocs(q);

        const newData = querySnapshot.docs
          .filter((doc) => {
            if (showFullYear) return true;
            const date = doc.data().date.toDate();
            return date.getMonth() === currentMonth && date.getFullYear() === currentYear;
          })
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            date: formatDate(doc.data().date),
          }));

        setData(newData);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [sortDirection, sortColumn, currentMonth, currentYear, showFullYear, refreshData]);

  const handleCategoryChange = async (itemId, newCategory) => {
    try {
      const itemRef = doc(firestore, 'transactions', itemId);
      await updateDoc(itemRef, { category: newCategory });

      const updatedData = data.map((item) =>
        item.id === itemId ? { ...item, category: newCategory } : item
      );
      setData(updatedData);
    } catch (error) {
      console.error('Error updating category:', error.message);
    }
  };

  const handleSort = (column) => {
    setSortColumn(column);
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));

    const sortedData = [...data].sort((a, b) => {
      const valueA = a[column] || '';
      const valueB = b[column] || '';

      if (sortDirection === 'asc') {
        return String(valueA).localeCompare(String(valueB), undefined, { numeric: true, sensitivity: 'base' });
      } else {
        return String(valueB).localeCompare(String(valueA), undefined, { numeric: true, sensitivity: 'base' });
      }
    });

    setData(sortedData);
  };

  const filteredData = data.filter((item) => {
    if (selectedCategories.length === 0) return true;

    const isSelected = selectedCategories.some(selected => {
      if (selected.value === 'Unknown') {
        return !item.category ||
          item.category.trim() === '' ||
          !categories.includes(item.category);
      }

      if (categoryGroups[selected.value]) {
        return categoryGroups[selected.value].includes(item.category?.toLowerCase());
      }

      return selected.value.toLowerCase() === item.category?.toLowerCase();
    });

    return invertSelection ? !isSelected : isSelected;
  });

  useEffect(() => {
    let total = 0;
    filteredData.forEach((item) => {
      total += parseFloat(item.amount);
    });
    setTotalAmount(total);
  }, [filteredData]);

  const handleAmountEdit = async (itemId, newAmount) => {
    try {
      const itemRef = doc(firestore, 'transactions', itemId);
      await updateDoc(itemRef, { amount: newAmount });

      const updatedData = data.map((item) =>
        item.id === itemId ? { ...item, amount: newAmount } : item
      );
      setData(updatedData);

      return Promise.resolve();
    } catch (error) {
      console.error('Error updating amount:', error.message);
      return Promise.reject(error);
    }
  };

  const handleEditClick = (itemId, amount) => {
    setIsEditing(itemId);
    setEditAmount(amount);
    setTimeout(() => {
      const input = document.getElementById(`amount-input-${itemId}`);
      if (input) {
        input.type = 'text';
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
        input.type = 'number';
      }
    }, 0);
  };

  const formatAmount = (amount) => {
    return `$${parseFloat(amount).toFixed(2)}`;
  };

  const handleCreateBillPrompt = async (transaction) => {
    const userConfirmed = window.confirm("Would you like to create a bill from this transaction?");
    if (userConfirmed) {
      await createBillFromTransaction(transaction);
    }
  };

  const createBillFromTransaction = async (transaction) => {
    try {
      const transactionDate = new Date(transaction.date);
      const transactionDay = transactionDate.getDate();

      let dueDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

      const lastDayOfCurrentMonth = new Date(dueDate.getFullYear(), dueDate.getMonth() + 1, 0).getDate();

      if (transactionDay > lastDayOfCurrentMonth) {
        dueDate.setDate(lastDayOfCurrentMonth);
      } else {
        dueDate.setDate(transactionDay);
      }

      const formattedDueDate = (dueDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
        dueDate.getDate().toString().padStart(2, '0') + '/' +
        dueDate.getFullYear();

      const billData = {
        amount: transaction.amount.toString(),
        dueDate: formattedDueDate,
        frequency: 'monthly',
        paid: false,
        status: 'active',
        title: transaction.location,
      };

      await addDoc(collection(firestore, 'bills'), billData);
    } catch (error) {
      console.error('Error creating bill:', error.message);
      alert('Failed to create bill.');
    }
  };

  const handleLocationEdit = async (itemId, newLocation) => {
    try {
      const itemRef = doc(firestore, 'transactions', itemId);
      await updateDoc(itemRef, { location: newLocation });

      const updatedData = data.map((item) =>
        item.id === itemId ? { ...item, location: newLocation } : item
      );
      setData(updatedData);
    } catch (error) {
      console.error('Error updating location:', error.message);
    }
  };

  const handleInvertSelectionChange = () => {
    setInvertSelection(!invertSelection);
  };

  return (
    <CommonContainer>
      <CommonTitle>Transactions</CommonTitle>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <CommonButton onClick={handlePrevMonth} disabled={showFullYear}>
          Previous Month
        </CommonButton>
        <CommonButton onClick={handleNextMonth} disabled={currentMonth === new Date().getMonth() && currentYear === new Date().getFullYear()}>
          Next Month
        </CommonButton>
        <CommonButton onClick={handleShowFullYear}>
          {showFullYear ? 'Show Current Month' : 'Show Full Calendar Year'}
        </CommonButton>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <CommonButton onClick={refreshDataCall}>Refresh Data</CommonButton>
        <Select
          isMulti
          options={[
            ...Object.keys(categoryGroups).map(group => ({ value: group, label: group })),
            ...categories.map(category => ({ value: category, label: category }))
          ]}
          value={selectedCategories}
          onChange={setSelectedCategories}
          placeholder="Select categories or groups to filter"
        />
        <label style={{ marginLeft: '10px' }}>
          <input
            type="checkbox"
            checked={invertSelection}
            onChange={handleInvertSelectionChange}
          />
          Invert Selection
        </label>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader onClick={() => handleSort('date')}>Date</TableHeader>
                <TableHeader onClick={() => handleSort('location')}>Location</TableHeader>
                <TableHeader onClick={() => handleSort('category')}>Category</TableHeader>
                <TableHeader onClick={() => handleSort('amount')}>Amount</TableHeader>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <TableRow key={item.id}>
                  <TableCell onClick={() => handleCreateBillPrompt(item)}>{item.date}</TableCell>
                  <TableCell
                    onClick={() => {
                      const newLocation = prompt('Enter new location:', item.location);
                      if (newLocation !== null) {
                        handleLocationEdit(item.id, newLocation);
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {item.location} - {item.user || 'Jeremiah'}
                  </TableCell>
                  <TableCell>
                    <StyledSelect
                      value={!item.category || item.category.trim() === '' || !categories.includes(item.category) ? 'Unknown' : item.category}
                      onChange={(e) => {
                        const newCategory = e.target.value === 'Unknown' ? '' : e.target.value;
                        handleCategoryChange(item.id, newCategory);
                      }}
                    >
                      <option value="Unknown">Unknown</option>
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </StyledSelect>

                  </TableCell>
                  <TableCell>
                    {isEditing === item.id ? (
                      <CommonInput
                        id={`amount-input-${item.id}`}
                        type="number"
                        value={editAmount}
                        onChange={(e) => setEditAmount(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(item.id, e)}
                        onBlur={async () => {
                          setIsEditing(null);
                          await handleAmountEdit(item.id, editAmount);
                        }}
                        style={{ width: '60px' }}
                      />
                    ) : (
                      <div
                        onClick={() => handleEditClick(item.id, item.amount)}
                        style={{ cursor: 'pointer' }}
                      >
                        {formatAmount(item.amount)}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
      <CommonBottomBar>
        <div>Total: {formatAmount(totalAmount)}</div>
      </CommonBottomBar>
    </CommonContainer>
  );
};

export default Transactions;
